import React from "react";
import { Helmet } from "react-helmet";
import SevenDaysBanner from "../components/SevenDaysBanner";
import DefaultHero from "../components/DefaultHero";
import Alarmanlagen from "../components/Alarmanlagen";
import Footer from "../components/Footer";
import OurTeam from "../components/OurTeam";
import { StaticQuery, graphql } from "gatsby";
import HowTo2 from "../components/HowTo2";
import FaceToFace from "../components/FaceToFace";
import IntrusionAtlas from "../components/IntrusionAtlas";

export const HeroElement = ({ context }) => {
    return (
        <div className="homepage">
            <IntrusionAtlas context={context} />
        </div>
    );
};

export default () => {
    console.log(process.env.GATSBY_SITE_URL);
    return (
        <StaticQuery
            query={graphql`
                query IntrusionAtlasQuery {
                    site {
                        siteMetadata {
                            title
                            image
                            image768
                            image1024
                            image1216
                            image1408
                        }
                    }
                }
            `}
            render={(data) => {
                const contextData = {
                    title: "Wie sicher ist Ihre Nachbarschaft? Unser Einbruchsatlas",
                    image: "/img/hero/einbruchsatlas/einbruchsatlas-von-meinalarm24.jpg",
                    image768: "/img/hero/empty.gif",
                    image1024:
                        "/img/hero/einbruchsatlas/einbruchsatlas-von-meinalarm24.jpg",
                    image1216:
                        "/img/hero/einbruchsatlas/einbruchsatlas-von-meinalarm24.jpg",
                    image1408:
                        "/img/hero/einbruchsatlas/einbruchsatlas-von-meinalarm24.jpg",
                    defaultCssClass: "intrusion-hero",
                    showPriceCalculator: false,
                    showHeroMobile: false,
                    showBottom: true,
                    disableBackground: true,
                };
                contextData.showElement = <HeroElement context={contextData} />;
                return (
                    <div>
                        <Helmet>
                            <html className="has-navbar-fixed-top" />
                            <title>{contextData.title}</title>
                            <meta
                                name="description"
                                content="Wie hoch ist das Einbruchsrisiko in Ihrer Nachbarschaft? Einfach PLZ eingeben - unser Algorithmus wertet das Sicherheitsrisiko aus und nennt Ihnen die wichtigsten Kennzahlen. "
                            />
                            <meta name="keywords" content="" />
                            <meta property="og:type" content="article" />
                            <meta
                                property="og:title"
                                content={contextData.title}
                            />
                            <meta
                                property="og:description"
                                content="Wie hoch ist das Einbruchsrisiko in Ihrer Nachbarschaft? Einfach PLZ eingeben - unser Algorithmus wertet das Sicherheitsrisiko aus und nennt Ihnen die wichtigsten Kennzahlen.  "
                            />
                            <meta
                                property="og:image"
                                content={contextData.image}
                            />
                        </Helmet>
                        <DefaultHero
                            context={contextData}
                            defaultCssClass="intrusion-atlas2"
                        />
                        <HowTo2 />
                        {/* <FaceToFace/>
            <OurTeam/>
            <Alarmanlagen /> */}
                        <SevenDaysBanner />
                        <Footer />
                    </div>
                );
            }}
        />
    );
};
